export default function() {
  // var $j = jQuery
  // $j(document).ready(function($) {
  // ●menuクラスの一番目がクリックされたら
  //   $('.menu')[0].addEventListener('click', function() {
  // ●activeクラスをつけたり消したり
  //     $('.menu__line').toggleClass('active')
  // ●top_navクラスはdisplaynoneかblockか
  //     $('.top_nav').fadeToggle()
  //   })
  // })

  new Vue({
    el: '#pokem_header',
    data: {
      isActive: false,
      nav: false,
      center: true
    },
    created() {
      window.onload = () => {
        this.resize()
      }
    },
    mounted() {
      window.addEventListener('resize', this.resize)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resize)
    },
    methods: {
      resize() {
        if (window.innerWidth > 767) {
          // ===pc表示=====
          // メニューを表示
          this.nav = true
          if (this.nav === true) {
            // humbergurmenu
            this.isActive = false
            this.center = true
          }
        } else {
          // ===sp表示======
          // メニューを非表示
          this.nav = false
        }
      },
      openmenu() {
        this.isActive = !this.isActive
        this.center = !this.center
        this.nav = !this.nav
      }
    }
  })
}
