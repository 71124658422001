export default () => {
  const $j = jQuery
  $j(function() {
    const $form = $j('#ir_inquiry_form')

    if (!$form.length) return

    const $check = $j('#ir_checkbox')
    const $submit = $j('#ir_btn_submit')
    const $required = $j('.req')

    $check.on('change', e => {
      e.target.checked
        ? $submit.removeAttr('disabled').addClass('agree')
        : $submit.attr('disabled', 'disabled').removeClass('agree')
    })

    $submit.on('click', e => {
      e.preventDefault()

      $required.each((i, el) => {
        if (el.value === '') {
          $j(el)
            .addClass('__ng')
            .next('.feedback')
            .text('入力に誤りがあります')
        } else {
          $j(el)
            .removeClass('__ng')
            .next('.feedback')
            .text('')
        }
      })
      const mailReg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
      const mailAddress = $j('#ir_email').val()
      if (!mailReg.test(mailAddress)) {
        $j('#ir_email')
          .addClass('__ng')
          .next('.feedback')
          .text('入力に誤りがあります')
      } else {
        $j('#ir_email')
          .removeClass('__ng')
          .next('.feedback')
          .text('')
      }
      if ($j('.__ng').length) {
        $j('.__ng')[0].focus()
        return
      } else {
        $form.submit()
      }
    })
  })
}
