export default function() {
  window.addEventListener('DOMContentLoaded', () => {
    var $j = jQuery
    $j(function() {
      if ($j('#blogimg').length) {
        const eyecatch = document.getElementById('blogimg')
        const whitelogo = document.getElementById('logo_txt_white')
        const blacklogo = document.getElementById('logo_txt')
        // ヘッダー画像通り過ぎたタイミングで切り替えるとき使う
        // const eyecatchHeight = eyecatch.clientHeight
        window.addEventListener(
          'scroll',
          function() {
            const eyecatchPosition = eyecatch.getBoundingClientRect().top * -1
            // ヘッダー背景白くするとき
            if (eyecatchPosition < 1) {
              blogbody.classList.add('single-blog')
              whitelogo.classList.remove('logo_txt_white')
              blacklogo.classList.add('logo_txt')
            } else {
              // ヘッダー背景透過させるとき
              blogbody.classList.remove('single-blog')
              whitelogo.classList.add('logo_txt_white')
              blacklogo.classList.remove('logo_txt')
            }
          },
          false
        )
      }
    })
  })
}
