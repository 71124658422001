import defaultExport from './_service'
import header from './_header'
import contact from './_contact'
import blog from './_blog'
import blogsingle from './_blogsingle'
import news from './_news'
import sustainability from './_sustainability'
import top from './_top'
import relatedpopulation from './_relatedpopulation'
import topen from './_topen'
import ir_inquiry from './_ir_inquiry'
defaultExport()
header()
contact()
blog()
blogsingle()
news()
sustainability()
top()
relatedpopulation()
topen()
ir_inquiry()
