export default function() {
  // ====戻った時内容をリセット========
  window.addEventListener('pagehide', () => {
    document.form_name.reset()
  })
  // =====URLparmにidがあるとき======
  var urlParam = location.search.substring(4)
  window.addEventListener('DOMContentLoaded', () => {
    // URLにパラメータが存在する場合,その選択肢を選択済にする
    if (urlParam == 'lecture') {
      document.getElementById('req').options[2].selected = true
    } else if (urlParam == 'alliance') {
      document.getElementById('req').options[3].selected = true
    }
    var $j = jQuery
    $j(function() {
      if ($j('#btn_submit').length) {
        // ==========バリデート===========
        const btn = document.getElementById('btn_submit')
        btn.addEventListener('click', e => {
          e.preventDefault()
          // e.stopPropagation()

          // 入力必須
          const feedback = document.querySelectorAll('.feedback')
          const req = document.querySelectorAll('.req')
          const reqCount = req.length
          let is_ok = []
          for (let i = 0; i < reqCount; i++) {
            if (req[i].value === '') {
              feedback[i].textContent = '入力に誤りがあります'
              is_ok[i] = 'ng'
            } else {
              is_ok[i] = 'ok'
              feedback[i].textContent = ''
            }
          }
          // メールアドレス
          const mailAddress = document.getElementById('email').value
          const regexp = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
          if (!regexp.test(mailAddress)) {
            feedback[4].textContent = '入力に誤りがあります'
          }
          // 送信
          if (!is_ok.includes('ng') && regexp.test(mailAddress)) {
            document.form_name.submit()
          }
          //間違っているreq[i]を取得して配列fault[i]に入れる
          for (let j = 0; j < reqCount; j++) {
            if (req[j].value === '') {
              req[j].focus()
              break
            } else if (j === 4 && !regexp.test(mailAddress)) {
              req[4].focus()
              break
            }
          }
          // ngがあった時にその場所に飛ぶ
        })
        const submit = document.getElementById('btn_submit')
        const checkbox = document.getElementById('checkbox')
        checkbox.addEventListener('change', () => {
          if (checkbox.checked) {
            submit.classList.add('agree')
            submit.disabled = false
          } else {
            submit.classList.remove('agree')
            submit.disabled = true
          }
        })
      }
    })
  })
}
