export default function() {
  window.addEventListener('DOMContentLoaded', function() {
    var $j = jQuery
    $j(function() {
      if ($j('#sliderbox').length) {
        function scrollEvent() {
          //  ■最後の要素取得
          var sliderbox = document.getElementById('sliderbox')
          sliderbox.scrollLeft = sliderbox.scrollLeft + 1
          // ■全体幅
          var scrollWidth = sliderbox.scrollWidth
          var clientWidth = sliderbox.clientWidth
          // ■最後のブロックに来た時(スクロール量 = 全体幅)スクロールバーを０に戻す
          if (sliderbox.scrollLeft >= scrollWidth - clientWidth) {
            sliderbox.scrollLeft = 0
          }
        }
        if (window.innerWidth < 768) {
          window.setInterval(scrollEvent, 15)
        } else {
          window.setInterval(scrollEvent, 10)
        }
      }
    })
  })
}
