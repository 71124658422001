export default function() {
  window.addEventListener('DOMContentLoaded', () => {
    var $j = jQuery
    $j(function() {
      if ($j('#topenjs').length) {
        const blogbody = document.getElementById('blogbody')
        // const whitelogo = document.getElementById('logo_txt_white')
        const blacklogo = document.getElementById('logo_txt')
        // ヘッダー画像通り過ぎたタイミングで切り替えるとき使う
        // const blogheaderHeight = blogheader.clientHeight
        window.addEventListener(
          'scroll',
          function() {
            const blogbodyPosition = blogbody.getBoundingClientRect().top * -1
            // ヘッダー背景白くするとき
            if (blogbodyPosition > 1) {
              blogbody.classList.remove('page-id-2994')
              // whitelogo.classList.add('logo_txt_white')
              blacklogo.classList.remove('logo_txt')
            } else {
              // ヘッダー背景透過させるとき
              blogbody.classList.add('page-id-2994')
              // whitelogo.classList.remove('logo_txt_white')
              blacklogo.classList.add('logo_txt')
            }
          },
          false
        )
      }
    })
  })
}
